<template>
    <div>
        <v-card color="white">
            <v-card-text style="font-size: 12px">
                <h2 class="teal--text">Subject: {{ticketDetail.subject}}</h2> 
                <v-layout row >
                    <v-flex xs5 >
                        <span><b>Category:</b> {{ticketDetail.category}}</span>
                    </v-flex>
                    <v-flex xs7 >
                        <span ><b>Date:</b> {{ticketDetail.get_date_str }}</span>
                    </v-flex>
                </v-layout>
                <p class="pa-0 ma-0" style="font-size: 12px"><b>Message:</b> {{ticketDetail.message}}</p>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-expansion-panel expand>
            <v-expansion-panel-content class="primary darken-2 white--text " style="color: white!important" >
                <div slot="header" class="text-xs-center white--text  ">
                    Reply Messages
                </div>
                <v-card color="white pa-2 mt-2" style="" >
                    <v-layout row wrap>
                        <v-flex xs12 class="pa-2">            
                            <v-layout row>
                                <v-flex xs2 sm1 class="text-xs-left">
                                    <i class="teal--text fas fa-lightbulb fa-2x"></i>
                                </v-flex>
                                <v-flex xs9 sm10>
                                    <v-text-field class="pa-0 mt-0"
                                        id="subject" ref="subject" v-model="subject"
                                        label="Subject"
                                        color="teal"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs1 >
                                    <v-btn @click.stop="$store.commit('activateDialog', true); subject_help = true; getHelpMessage()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                        <i class="fas fa-question"> </i>                            
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs2 sm1 class="text-xs-left mt-2">
                                    <i class="teal--text fas fa-comment fa-2x"> </i>                            
                                </v-flex>
                                <v-flex xs9 sm10>
                                        <v-textarea class="pa-0 mt-0 ml-1"
                                            name="input-7-1"
                                            auto-grow  v-model="message"
                                            ref="message"
                                            color="teal"
                                            label="Message"
                                            :rules="[v => !!v || 'Message is required']"
                                            background-color=" lighten-4"
                                            rows="2" box
                                            row-height="20" 
                                        ></v-textarea>
                                </v-flex>
                                <v-flex xs1 >
                                    <v-btn @click.stop="$store.commit('activateDialog', true); message_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-3 ml-2" style="height:20px; width: 20px" >
                                        <i class="fas fa-question"> </i>                            
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs11 >
                                    <div class="mt-0" style="border-bottom: 1px solid grey; width: 100%; cursor: pointer"  @click="$refs.file.click()">
                                        <input @change="fileInput()" type="file" ref="file" name="file" id="file" class="inputfile" accept=".jpg,.png,.jpeg,.pdf" style=""/>
                                        <label for="file" class="teal"><strong><i class="fas fa-upload "> </i> Upload</strong></label>
                                        <span class="text-xs-center" style="font-size: 12px; width: 100%">  {{inputFileName}}</span>
                                    </div>
                                </v-flex>
                                <v-flex xs1 >
                                    <v-btn @click.stop="$store.commit('activateDialog', true); file_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-2 ml-2" style="height:20px; width: 20px" >
                                        <i class="fas fa-question"> </i>                            
                                    </v-btn>
                                </v-flex>
                            </v-layout>

                            <div class=" text-xs-center">
                                <v-btn small class="white--text elevation-6" round color="teal" @click="replyTicket()">
                                    <span> Submit</span>
                                </v-btn>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-divider></v-divider>
        <div class="mt-3 pa-0" style="max-height: 600px; overflow: auto"> 
            <v-card v-for="item in ticketChats"
                :key="item.key" 
            >
                <v-card-text class="px-3 py-2 " v-if="item.support_team" style="border-left: 5px solid grey; font-size: 12px; cursor: pointer">
                    <v-layout row wrap>
                        <v-flex xs12 md8>
                            <h3 >Subject: {{item.subject}}<blink v-if="!item.read" class="pt-0 mt-0" style="font-size: 8px; position: relative; top: 0"><i class="red--text fas fa-circle"></i></blink></h3>
                        </v-flex>
                        <v-flex xs12 md4>
                            <div >
                                <span><b>Date:</b> {{item.get_date_str}} </span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <span><b>Message:</b> {{item.message}}</span>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-text class="px-3 py-2 " v-else style="border-left: 5px solid green; font-size: 12px; cursor: pointer">
                    <v-layout row wrap>
                        <v-flex xs12 md8>
                            <h3 >Subject: {{item.subject}}<blink v-if="!item.read" class="pt-0 mt-0" style="font-size: 8px; position: relative; top: 0"><i class="red--text fas fa-circle"></i></blink></h3>
                        </v-flex>
                        <v-flex xs12 md4>
                            <div >
                                <span><b>Date:</b> {{item.get_date_str}} </span>
                            </div>
                        </v-flex>
                    </v-layout>
                    <span><b>Message:</b> {{item.message}}</span>
                    <v-spacer></v-spacer>
                </v-card-text>
            <v-divider></v-divider>
            <v-divider></v-divider>
            </v-card>
        </div>

    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                valid: true,
                subject: '',
                message: '',
                file: '',
                subject_help: false,
                message_help: false,
                file_help: false,
                inputFileName: "Select Accompanying File",
                ticketDetail: [],
                ticketChats: [],
            }
        },

        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash Savings | Contact Us"
            this.getTicketDetail()
            this.getTicketChats()
            this.$store.commit('setCloseURL', "/market_place/contact_us/all")
        },
        methods: {

            fileInput(){
                var File= document.getElementById('file')
                if(File.files.item(0)){
                    var FileName = File.files.item(0).name
                    var FileSize = File.files.item(0).size
                    var FileType = File.files.item(0).type
                    console.log(FileType)
                    FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                    var win = window,
                        doc = document,
                        docElem = doc.documentElement,
                        body = doc.getElementsByTagName('body')[0],
                        screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                        screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                    
                    if (FileSize >= 5242880){
                        this.$store.commit('setSnackBarMessage', "Your accompanying file exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Accompanying File"
                    }
                    else if(!FileType.includes("pdf") && !FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png") && !FileSize.includes("doc")){
                        this.$store.commit('setSnackBarMessage', "Your accompanying file must of format PDF, Image (.png, .jpeg, .jpg) or a file of format .doc")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Accompanying File"
                    }
                    else{
                        if (FileName.length > 20){
                            if (screenWidth <= 760){
                                FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                            }
                        }
                        this.inputFileName = FileName
                        this.file = this.$refs.file.files[0]
                    }
                }
                else{
                    this.inputFileName = "Select Accompanying File"
                }
            },
            
            async getTicketDetail(){
                this.$store.commit('setIsLoading', true)
                const ticket_id = this.$route.params.ticket_id  // get campaign category from url and send request to server
                
                await axios
                    .get('/api/v1/home/Market Place/contact_us/'+ticket_id)
                    .then(response => {
                        this.ticketDetail = response.data  // get the data and fill into operators
                        console.log(this.ticketChats)
                    })
                    .catch(error => {
                        if (error.response){
                            for (const property in error.response.data){
                                this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getTicketChats(){
                this.$store.commit('setIsLoading', true)
                const ticket_id = this.$route.params.ticket_id  // get campaign category from url and send request to server
                
                await axios
                    .get('/api/v1/home/Market Place/contact_us/'+ticket_id+'/chats/')
                    .then(response => {
                        this.ticketChats = response.data  // get the data and fill into operators
                        console.log(this.ticketChats)
                    })
                    .catch(error => {
                        if (error.response){
                            for (const property in error.response.data){
                                this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            getHelpMessage(){
                if (this.subject_help){
                    this.subject_help = false
                    this.$store.commit('setDialogTitle', "Subject Name")
                    this.$store.commit('setDialogMessage', "Please input title to your reply. A short phrase describing the reply.")
                    this.$store.commit('activateDialog', true)
                }else if (this.message_help){
                    this.message_help = false
                    this.$store.commit('setDialogTitle', "Message")
                    this.$store.commit('setDialogMessage', "You can type in the message here.")
                    this.$store.commit('activateDialog', true)
                }else if (this.file_help){
                    this.file_help = false
                    this.$store.commit('setDialogTitle', "Accompanying Files")
                    this.$store.commit('setDialogMessage', "Any images/screenshots that may help in us better understand your message can be uploaded here.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async replyTicket(){
                var File= document.getElementById('file')

                if(this.subject === ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the subject")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.subject.focus()  // this causes a an auto fucos to the element
                }
                else if(this.message == ""){
                    this.$store.commit('setSnackBarMessage', "Please fill in the message you want to send")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                
                    let formData = new FormData()
                    formData.append('file', this.file)
                    formData.append('subject', this.subject)
                    formData.append('message', this.message)
                    console.log(this.file)
                    const ticket_id = this.$route.params.ticket_id  // get campaign category from url and send request to server

                    await axios
                        .post('/api/v1/home/Market Place/contact_us/'+ticket_id+'/chats/reply/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            if(response.data[0].id === "0"){
                                this.$router.push('/market_place/contact_us/detail/'+ticket_id)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },

        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

</style>

